import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import {getFeatures, getPahts, getConfig} from './helpers/helper.js'
import './App.css';
import Marker from './components/Marker.jsx';

// Set your Mapbox access token here
mapboxgl.accessToken = 'pk.eyJ1IjoidGhlYWJkdWxyYXp6YXEiLCJhIjoiY2x2eHAyaXl6MjA0OTJrbnlocWE5cWZidSJ9.uco162Oe0SX8gWyhDGwSfg';

const App = () => {
	const mapContainerRef = useRef(null);
	const [map, setMap] = useState(null);
	const [config, setConfig] = useState(getConfig());

	const render_source = (id, map, feature, paint) =>{

		const featureType = feature.geometry.type;
		const isPoint = featureType == "Point";
		const isLine = !isPoint;

		if(isLine){
			const path = feature.properties.Path;
			

			switch (path) {
				case 'Buffalo to lockport':
				case 'Lockport to toronto':
				case 'London to Paris':

				paint["line-color"] = config.pathColorSecondary;
					
					break;
			
				default:
					break;
			}
		}

		map.addSource(id, {
			type: 'geojson',
			data: feature
		  });
  
		  map.addLayer({
			id: id,
			source: id,
			type: feature.geometry.type == "Point" ? 'circle' : "line",
			paint: paint
		  });
	}

	useEffect(() => {
		
		const allFeatures = getFeatures();
		const allPaths = getPahts();
		const config = getConfig();
		const initializeMap = () => {
			const map = new mapboxgl.Map({
				container: mapContainerRef.current,
				style: 'mapbox://styles/theabdulrazzaq/clwgdqbr500cc01qx1ojpctur', // Use your preferred Mapbox style here
				center: [-41.84361787258639, 47.753595107155974], // Initial map center [lng, lat]
				zoom: 3.5, // Initial zoom level
				projection: 'mercator',
				// projection: 'globe'
			});

			map.on('load', () => {
				// Map loaded callback
				let count = 1;
				allFeatures.forEach((feature) => {
					const id = `point_${count}`;	
					const id_2 = `point_2_${count}`;	
					const id_3 = `point_3_${count}`;

					const layerType = feature.geometry.type;
					let paint = {};

					if(layerType === 'Point'){
						paint = {
							'circle-radius': 15,
							'circle-color': config.pathColor,
							'circle-opacity': 0.2,
						  }

						  const markerNode = document.createElement('div');
						  markerNode.style.position = 'absolute'; // Ensure marker is positioned correctly
  
						  // Render Marker component into the markerNode
						  ReactDOM.render(<Marker key={id} feature={feature}/>, markerNode);
  
						  // Add marker to the map
						  const marker = new mapboxgl.Marker(markerNode)
							  .setLngLat(feature.geometry.coordinates)
							  .addTo(map);

							  render_source(id, map, feature, paint);

					}else{

						paint = {
							'line-color': config.pathColor,
							'line-width': 9,
							'line-opacity': 0.3
						  }

						  render_source(id_2, map, feature, paint);

						  paint = {
							'line-color': config.pathColor,
							'line-width': 6,
							'line-opacity': 0.3
						  }

						  render_source(id_3, map, feature, paint);


						  paint['line-width'] = 3;
						  paint['line-opacity'] = 1;

						  render_source(id, map, feature, paint);
					}
	
					count++;	

		
				})
			});

			const nav = new mapboxgl.NavigationControl();
			map.addControl(nav, 'bottom-right');
			setMap(map);
		};

		if (!map) initializeMap();

		// Clean up
		return () => map && map.remove();
	}, [map]);

	return <div className="map-container" ref={mapContainerRef} style={{ width: '100%', height: '80vh' }} />;
};

export default App;
