import { useEffect, useState } from 'react';

const Pop = ({name}) => {
	const [ isPopoverOpen, setIsPopoverOpen ] = useState( true );
	useEffect( () => {
		console.log( isPopoverOpen );
	}, [] );

	return (
		<div className="popup arrow-left">
			<div className="popup-wrapper">
				<h2>{name}</h2>
			</div>
		</div>
	);
};

export default Pop;
