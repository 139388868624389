import React, { useEffect, useState } from 'react';
import Pop from './Popover.jsx';

function Marker( { feature } ) {
	const [ name, setName ] = useState( feature.properties.Name );
	useEffect( () => {
		console.log( feature );
		console.log( name );
	}, [] );
	const al = () => {
		alert( 'hello' );
	};
	return (
		<div className="marker">
			<div className="popover">
				{ name }
				<div className="popover__nub"></div>
			</div>
			<div className="marker__wrap" onClick={ al }>
				<div className="marker__inner">
          
        </div>
			</div>
      <Pop name={name}/>
		</div>
	);
}

export default Marker;
