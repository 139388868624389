import paths from '../data/paths.json';
import config from '../data/config.json';


export const getFeatures = ()=> {
    const features = paths.features;
    return features;    
}

export const getPahts = ()=> {
    return paths;
}

export const getConfig = (key = null)=> {
        
    if(key){
        if(confg.hasOwnProperty(key)){
            return config[key];
        }        
    }

    return config;
}

